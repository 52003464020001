import React, { useState, useEffect } from 'react';
import { Select, Button, Table, message, Card, Row, Col, Space } from 'antd';
import * as XLSX from 'xlsx';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;

// Fonction pour récupérer la liste des départements depuis l'API
const fetchDepartments = async () => {
  try {
    // Faire une requête GET sur l'URL de l'API
    const response = await axios.get(
      'http://157.245.65.182:4567/all-departments'
    );
    // Retourner la liste des départements
    return response.data;
  } catch (error) {
    // Afficher un message d'erreur en cas de problème
    message.error('Erreur lors de la récupération des départements');
  }
};

// Fonction pour générer des données selon le format demandé depuis l'API
const generateData = async (department) => {
  try {
    // Faire une requête POST sur l'URL de l'API avec le paramètre size=10
    const response = await axios.get(
      'http://157.245.65.182:4567/get-by-departements/'+department,
      { size: 10 }
    );
    // Retourner la liste des objets aléatoires en ajoutant le département
    return response.data.map((item, index) => ({
      key: index,
      siret: item.siret,
      enseigne: item.enseigne,
      statut: item.statut,
      phone: item.phone,
      email: item.email,
      scrapedAt: moment().format('DD-MM-YYYY HH:mm')
      // nom: item.nom,
    }));
  } catch (error) {
    // Afficher un message d'erreur en cas de problème
    message.error('Erreur lors de la génération des données');
  }
};

// Fonction pour exporter les données en excel
const exportToExcel = (depart, data) => {
  // Créer un workbook
  const workbook = XLSX.utils.book_new();
  // Convertir les données en feuille de calcul
  const worksheet = XLSX.utils.json_to_sheet(data);
  // Ajouter la feuille de calcul au workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Données');
  // Télécharger le fichier excel
  XLSX.writeFile(workbook, depart.split(' ')[0]+'-data'+moment().format('YYYYMMDDHHmm')+'.xlsx');
};

// Composant principal de l'application
const App = () => {
  // État pour stocker le département sélectionné
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  // État pour stocker les données du tableau
  const [tableData, setTableData] = useState([]);
  // État pour stocker si le bouton valider est cliqué
  const [validated, setValidated] = useState(false);
  // État pour stocker la liste des départements
  const [departments, setDepartments] = useState([]);
  const [scrapIsLoading, setScrapIsLoading] = useState(false);

  // Effet pour récupérer la liste des départements au montage du composant
  useEffect(() => {
    const getDepartments = async () => {
      // Appeler la fonction fetchDepartments et stocker le résultat dans l'état departments
      const result = await fetchDepartments();
      setDepartments(result);
    };
    getDepartments();
  }, []);

  // Gérer le changement de sélection du département
  const handleSelectChange = (value) => {
    setSelectedDepartment(value);
    setValidated(false);
  };

  // Gérer le clic sur le bouton valider
  const handleValidateClick = async () => {
    setScrapIsLoading(true);
    if (selectedDepartment) {
      // Appeler la fonction generateData et stocker le résultat dans l'état tableData
      const data = await generateData(selectedDepartment);
      setTableData(data);
      // Mettre à jour l'état de validation
      setValidated(true);
      // Afficher un message de succès
      message.success('Données générées avec succès');
    } else {
      // Afficher un message d'erreur si aucun département n'est sélectionné
      message.error('Veuillez sélectionner un département');
    }
    setScrapIsLoading(false)
  };

  // Gérer le clic sur le bouton exporter
  const handleExportClick = () => {
    if (tableData.length > 0) {
      // Exporter les données en excel
      exportToExcel(selectedDepartment, tableData);
      // Afficher un message de succès
      message.success('Données exportées avec succès');
    } else {
      // Afficher un message d'erreur si aucune donnée n'est disponible
      message.error('Aucune donnée à exporter');
    }
  };

  // Définir les colonnes du tableau selon le format demandé
  const columns = [
    // {
    //   title: 'Nom',
    //   dataIndex: 'nom',
    //   key: 'nom',
    // },
    {
      title: 'Siret',
      dataIndex: 'siret',
      key: 'siret',
    },
    {
      title: 'Enseigne',
      dataIndex: 'enseigne',
      key: 'enseigne',
    },
    {
      title: 'Statut',
      dataIndex: 'statut',
      key: 'statut',
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // Rendre la colonne filtrable par email
      filters: [
        { text: 'Avec email', value: true },
        { text: 'Sans email', value: false },
      ],
      onFilter: (value, record) => {
        // Retourner les lignes qui correspondent au critère de filtrage
        return value ? record.email !== null : record.email === null;
      },
    },
    {
      title: 'Date de scrap',
      dataIndex: 'scrapedAt',
      key: 'scrapedAt',
    },
  ];

  return (
    <Card>
      <div className="App">
        <h1>Scrap https://www.aef.cci.fr/</h1>
        <Row style={{marginBottom: 30}}>
          <Col>
            <label>Sélectionnez un département:</label>
            <Space align='baseline' size={12}>
              <Select
                style={{ width: 200 }}
                onChange={handleSelectChange}
                value={selectedDepartment}
              >
                {departments?.map((department) => (
                  <Option key={department} value={department}>
                    {department}
                  </Option>
                ))}
              </Select>
              <Button type="primary" loading={scrapIsLoading} onClick={handleValidateClick}>
                Valider
              </Button>
              <Button type="default" onClick={handleExportClick}>
                Exporter en excel
              </Button>
            </Space>
          </Col>
        </Row>
        {validated && (
          <div className="table-container">
            <Table columns={columns} dataSource={tableData} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default App;